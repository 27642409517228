import React, { useState, useRef } from "react";
import renderHTMLToReact from "../../utils/html";

export default function Accordion({ question, answer }) {
  const [active, setActive] = useState("");
  const [height, setHeight] = useState("0px");

  const content = useRef(null);

  const openTab = () => {
    setActive(active === "" ? "active" : "");
    setHeight(
      active === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
  };
  return (
    <div className={`tab mx-auto border-b-1 border-gray-200 py-4`}
       itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question" >
      <h2
        onClick={() => openTab()}
        itemprop="name"
        className="cursor-pointer flex items-start font-semibold text-lg justify-between w-full text-left text-gray-800 dark:text-gray-300 transition-colors hover:text-gray-600"
      >
        <span>{question}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`h-6 w-6 ${
            active ? "transform rotate-180 dark:text-gray-300" : ""
          }`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </h2>
      <div
        itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer" 
        ref={content}
        style={{ maxHeight: `${height}` }}
        className={`tab-content mb-0  dark:text-gray-300 text-gray-500 overflow-hidden answer`}
      >
        <div itemprop="text" className="pt-2 pb-2">{renderHTMLToReact(answer)}</div>
      </div>
    </div>
  );
}
