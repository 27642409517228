import React, {useState, useEffect} from "react";
import { faqs } from "../../data/faq";
import Accordion from "./Accordion";
import {fetchIPJSON} from '../../help';
export default function FAQ() {
  const [country, setCountry] = useState(null);
  if (typeof window !== 'undefined'){
    const ip = window.localStorage.getItem('ip') || null;
    if(ip){
      fetchIPJSON(ip).then(res => {
        setCountry(res.country_name);
      });
    }
  }

  useEffect(()=>{
     let html = document.getElementsByTagName("html")[0];
     let att = document.createAttribute("itemscope");        
     att.value = "";           
     html.setAttributeNode(att); 
     let type = document.createAttribute("itemtype");        
     type.value = "https://schema.org/FAQPage";           
     html.setAttributeNode(type);
  }, [])
  return (
    <div className="xl:w-4/5 mx-auto">
      <div className="faq w-4/5 lg:w-4/5 mx-auto mt-20 relative z-10">
        <h3 className="text-3xl sm:text-4xl text-center my-5 mb-10 dark:text-gray-100 font-bold">
          Frequently asked questions
        </h3>
        <p className="text-center mb-10 text-xl text-gray-500 dark:text-gray-300 lg:px-40">
          Have a question? I’m confident you’ll find the answer here, but if not
          please feel free to {" "}
          <a
            href="mailto:ina.hranova@gmail.com"
            target="_blank"
            className="underline text-purple-900 hover:text-blue-400 dark:text-pink-400 dark:hover:text-pink-800"
          >
            drop me a line
          </a>
        </p>
        {faqs.map((faq, i) => (
          faq?.hiddenCountry && faq.hiddenCountry.includes(country) ?  (null) : (<Accordion answer={faq.answer} question={faq.question} key={i} />)
        ))}
      </div>
    </div>
  );
}
