export const faqs = [
  {
    question: "How much does a project cost? ",
    answer:
      "Pricing can vary greatly from project to project (based on the skills you require and your technical requirements). If you’re after a marketing site prices start from <b>1,950 euro</b> and if you’re after an eCommerce site prices start at <b>3,350 euro</b>.",
    hiddenCountry: ["Bulgaria","Malta"] 
  },
  {
    question: "What is your hourly rate?",
    answer:
      "My standard hourly rate is <b>€35</b>. But <b>between €35 and €30</b> I'd be happy to talk to you. ;) ",
    hiddenCountry: ["Bulgaria","Malta","Portugal"]  
  },
  {
    question: "How long will my project take?",
    answer:
      "A typical website with 4-6 different templates usually takes between 3-4 weeks to build (with full CMS options). Assuming timely delivery of content, assets etc.",
  },
  {
    question: "Will your front-end implementation be SEO-friendly?",
    answer:
      "Yes,I code with SEO in mind, because it's important SEO has become to running a successful website!",
  },
  {
    question: "How do payments work?",
    answer:
      "I take 50% upfront (before any work begins) and 50% on completion (development is complete). For bigger projects they’ll be billed 50% upfront and then 2 payments of 25% at agreed milestones.      ",
  },
  {
    question: "Do you provide an invoice for payment/transaction?",
    answer:
      "Yes,I provide a VAT invoice",
      hiddenCountry: ["Bulgaria","Malta","Portugal"]  
  },
  {
    question: "Are you available for an interesting job?",
    answer:
      "Unfortunately, I am currently not available for another job. But I'm open to freelance work or projects.",
      hiddenCountry: ["United States", "Europe"]  
  },
  {
    question: "Where are you located? Do you work remotely? ",
    answer:
      "I live in Sofia, Bulgaria. Is Bulgaria too far away from your organization? That shouldn't be a problem, since I'm used to working remotely.",
  },
  {
    question: "What was this site built with?",
    answer:
      "Gatsby.js (framework based on React) and Tailwind CSS .I'm big fan of Tailwind CSS. Because your classnames are 'composed' you don't have to switch between your markup and styles, you don't have to make concessions in terms of design and your CSS file is a lot smaller (with PurgeCSS). 👌",
  },
  {
    question:
      "Ok, enough about your work, what do you do when you're not in front of your laptop?",
    answer:
      "When I'm not at my laptop, I prefer to be outdoors. But I still like to sit behind my laptop, even when I'm free. Then I enjoy working on my side projects (think of tools, plugins and web apps based on Strapi/Node.js/React.js and React Native).",
  },
];
