import React from "react";

function About() {
  return (
    <div className="relative z-10 overflow-hidden-x">
      <div className=" xl:w-4/5 mx-auto mt-14">
        <div className="lg:w-3/5 dark:text-white mx-5 lg:mx-10 mb-10">
          <span className="block mb-4 text-sm font-semibold tracking-widest text-gray-500 dark:text-gray-300 uppercase">
            ABOUT ME
          </span>
          <h1 className="text-4xl sm:text-5xl mb-5 font-semibold dark:text-white">
            <b className="font-extrabold">7+ years</b> of experience as a
            developer
          </h1>
          <p className="mb-4 md:mb-5 text-gray-800 text-xl text-scale-1 dark:text-white">
            My focus these days is on front-end development, but I have a
            background in backend development and mobile development. I was able
            to improve my productivity when developing frontend and backend from
            scratch. Also I have knowledge of SEO and Google Services.
          </p>
          <p className="mb-4  md:mb-5 text-gray-800 text-xl text-scale-1 dark:text-white">
            I have 5+ years of experience in the web development field. You will
            find me developing creative websites using HTML, CSS/SASS,
            WordPress, JavaScript, jQuery, React, Gatsby, APIs, and mobile
            applications using React Native from scratch. I take pride in
            building websites and applications that are responsive, accessible,
            and have a great user experience. Though constantly changing, my
            focus right now is React.js/Gatsby.js/Next.js, React Native, Strapi,
            Firebase, TailwindCSS, and AWS.
          </p>
        </div>
        <div className="lg:w-3/5 mx-5 lg:mx-10">
          <span className="block mb-4 text-sm font-semibold tracking-widest text-gray-500 dark:text-gray-300 uppercase">
            BIO
          </span>
          <h2 className="block mb-4 text-3xl sm:font-semibold text-gray-800 dark:text-white">
            I have worked on more than <b>50 websites</b> for cool organizations
            and agencies and winning some USA awards.
          </h2>
          <p className="mb-4  md:mb-10 text-gray-800 text-xl leading-normal dark:text-white">
            From small startups to larger organizations, such as the US and BG
            agencies{" "}
          </p>
        </div>
        <div className="grid gap-10 xl:gap-0 grid-cols-3 flex -rotate-12 transform justify-center items-center w-3/4 lg:w-2/4 opacity-90 absolute top-1/4 -right-44 hidden lg:block z-20">
          <div className="col-4 flex flex-col items-center justify-center">
            <div className="card card-front-widget rounded-xl w-48 h-48">
              <img
                loading="lazy"
                src="https://screpy.com/wp-content/uploads/2021/01/hero-overal-widget.svg"
                alt=""
                title=""
                className="w-full h-full object-center object-cover"
              />
            </div>
            <div className="card card-front-widget rounded-xl shadow-2xl h-48 h-48 mt-10">
              <img
                loading="lazy"
                alt=""
                src="https://screpy.com/wp-content/uploads/2020/12/uptime-widget.svg"
                className="w-full h-full object-center object-cover"
              />
            </div>
          </div>
          <div className="col-4 flex flex-col items-center justify-center">
            <div className="card card-front-widget rounded-x shadow-2xl w-48 mt-10">
              <img
                loading="lazy"
                title=""
                alt=""
                src="https://screpy.com/wp-content/uploads/2020/12/seo-top-widget.svg"
                className="w-full h-full object-center object-cover"
              />
            </div>
            <div className="card card-front-widget rounded-xl h-48 h-48 mt-10">
              <img
                loading="lazy"
                src="https://screpy.com/wp-content/uploads/2020/12/pagespeed-hero-widget.svg"
                className="w-full h-full object-center object-cover"
              />
            </div>
            <div className="card card-front-widget rounded-xl shadow-2xl w-48 mt-10">
              <img
                loading="lazy"
                src="https://screpy.com/wp-content/uploads/2020/12/seo-detail-widget.svg"
                className="w-full h-full object-center object-cover"
              />
            </div>
          </div>
          <div className="col-4 flex flex-col items-center justify-center">
            <div className="card card-front-widget rounded-xl shadow-2xl  w-48 h-48 mt-10">
              <img
                loading="lazy"
                src="https://screpy.com/wp-content/uploads/2020/12/average-serp.svg"
                className="w-full h-full object-center object-cover"
              />
            </div>
            <div className="card card-front-widget rounded-xl  shadow-2xl w-48 mt-10">
              <img
                loading="lazy"
                src="https://screpy.com/wp-content/uploads/2020/12/uptime-status-widget.svg"
                className="w-full h-full object-center object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
