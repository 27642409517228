import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

export default function Jobs() {
  const data = useStaticQuery(graphql`
    {
      allJobsJson {
        nodes {
          date
          company
          name
          tech
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 100)
            }
          }
        }
      }
    }
  `);
  //console.log(data);
  const jobs = data.allJobsJson.nodes;
  return (
    <div className="jobs xl:w-4/5 mx-auto relative z-10">
      {jobs.map((job, x) => (
        <div
          key={x}
          className="job flex flex-row lg:w-3/5 mx-5 lg:mx-10 items-center my-4 pb-4 border-gray-200 dark:border-gray-800 border-b-2 relative z-100"
        >
          <div className="rounded-lg shadow-xl bg-white h-14 w-14 p-1 mr-5 ">
            <GatsbyImage
              image={getImage(job.thumbnail)}
              alt={job.name}
              className="object-cover object-center h-12 w-12"
            />
          </div>
          <div className="position">
            <p className="text-gray-800 mb-0 transition-colors text-scale-0 font-semibold leading-normal dark:text-white">
              {job.name}
            </p>
            <span className="text-sm text-gray-800 xs:text-base block dark:text-gray-500">
              {job.company}
            </span>
            <span className="text-sm text-gray-600 xs:text-base block dark:text-gray-300 leading-5">
              {job.date}
            </span>
            <span className="text-sm text-gray-500 xs:text-base block leading-normal dark:text-gray-300">
              {job.tech}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}
