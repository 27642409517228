import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import SEO from "../components/Layout/SEO";
import About from "../components/Sections/About";
import FAQ from "../components/Sections/FAQ";
import Jobs from "../components/Sections/Jobs";

const AboutPage = () => {
  return (
    <>
      <SEO
        title="About"
        description="Ina Hranova is a freelance front-end developer based in Bulgaria who specialises in React, React Native,Next.js,WordPress, HTML/CSS for top digital agencies."
      />
      <About />
      <Jobs />
      <FAQ />
    </>
  );
};
export default AboutPage;
